import { ref } from '@vue/composition-api'
import store from '@/store'
import { FETCH_COLLECTION_PRODUCTS } from '@/store/modules/collection.module'

export const useShopFiltersSortingAndPagination = () => {
  // Sorting
  const sortBy = ref({
    text: 'Default',
    value: 'default',
  })
  const sortByOptions = [
    {
      text: 'Default',
      value: 'default',
    },
    {
      text: 'Lowest',
      value: 'lowest',
    },
    {
      text: 'Highest',
      value: 'highest',
    },
  ]
  const sortByBrand = ref({
    text: 'Recommended',
    value: 'default',
  })

  return {
    // Sort
    sortBy,
    sortByOptions,
    sortByBrand,
  }
}

export const useShopUi = () => {
  const itemView = 'grid-view'
  const itemViewOptions = [
    {
      icon: 'GridIcon',
      value: 'grid-view',
    },
    {
      icon: 'ListIcon',
      value: 'list-view',
    },
  ]

  // Pagination count <= required by pagination component
  const totalProducts = ref(null)
  const isWspHidden = ref(false)
  const isBrandNotAccessible = ref(false)
  const connectionRequestStatus = ref(null)
  const brandId = ref(null)
  const filterByAvailabilityDates = ref({})

  return {
    itemView,
    itemViewOptions,
    totalProducts,
    isWspHidden,
    isBrandNotAccessible,
    connectionRequestStatus,
    brandId,
    filterByAvailabilityDates,
  }
}

export const useShopRemoteData = () => {
  const products = ref([])
  const fetchProducts = (...args) => store.dispatch(FETCH_COLLECTION_PRODUCTS, ...args)

  return {
    products,
    fetchProducts,
  }
}
