<template>
  <div class="checkout-items">
    <!-- loaded -->
    <template v-if="isLoaded">
      <b-modal
        id="buyingsummary"
        ref="buyingsummary"
        hide-header
        hide-header-close
        hide-footer
        size="xl"
        centered
      >
        <div class="pb-1 border-bottom">
          <span class="custom-modal-heading">Buying Summary</span>
          <feather-icon
            class="float-right close-x-icon"
            icon="XIcon"
            size="24"
            @click="closeModal"
          />
        </div>
        <buying-summary
          :cart-products="originalProducts"
          @closeModal="closeModal"
        />
      </b-modal>
      <!-- has product -->
      <template v-if="productsSlice.length || originalProducts.length">
        <b-row
          class="sticky-input"
          :class="isPopup ? 'sticky-input-popup' : ''"
        >
          <b-col class="col-11 col-md-6 col-lg-5">
            <b-form-input
              v-model="searchText"
              placeholder="Search by products, style code..."
              @input="searchProduct"
            />
            <feather-icon
              icon="SearchIcon"
              size="18"
              class="cart-search-icon"
            />
          </b-col>
          <b-col
            v-if="!isPopup"
            class="col-12 col-md-6 col-lg-7 d-flex justify-content-end"
          >
            <b-button
              v-b-modal.clear-cart
              class="mr-1"
              variant="outline-danger clear-cart-btn"
            >
              <shopping-bag-deletete-icon class="align-bottom" />
              Clear Cart
            </b-button>
            <b-button
              variant="outline-secondary-black"
              class="mr-1"
              @click="saveCartProducts"
            >
              Save Cart
            </b-button>
          </b-col>
        </b-row>
        <!-- Cart errors -->
        <cart-errors
          v-if="isCartHasErrors"
          :key="isCartHasErrors"
          :cart-id="$store.getters.getCart._id"
          :cart-products="products"
        />
        <div
          v-for="product in productsSlice"
          :key="product._id"
          class="mb-1 cart-product"
          :class="{'has-error' : isNotValidMinQty(product, product.lineTotalQuantity), 'is-product-not-valid' : isCartHasErrors && getCartProductsErrorStatusText(product), 'zero-qty-product': isCartHasErrors && isProductHasZeroQty(product)}"
        >
          <!-- cart error text -->
          <cart-product-error-text
            :error-text="getCartProductsErrorStatusText(product)"
          />
          <b-row
            class="mx-0"
            no-body
          >
            <!-- Product Image -->
            <b-col class="item-img col-md-3 col-xl-2 pl-0 col-12">
              <b-link
                :to="{name: 'product-detail', params: {id: product._id, collectionId:product.collectionId}}"
              >
                <b-img-lazy
                  class="imgcss"
                  :src="parseProductImage(product)"
                  :alt="`${product.name}-${product._id}`"
                  @error.native="imageLoadError"
                />
              </b-link>
            </b-col>

            <!-- Product Details: Card Body -->
            <b-col class="col-md-7 col-xl-8 border-right pt-2 d-flex flex-column col-12">
              <div class="item-name d-flex justify-content-between">
                <div>
                  <b-link
                    class="product-link"
                    :to="{name: 'product-detail', params: {id: product._id, collectionId:product.collectionId}}"
                  >
                    {{ product.name }}
                  </b-link>
                  <b-link class="company-name">
                    <b-breadcrumb
                      class="breadcrumb-products mb-0 pl-0"
                      :items="[product.department, product.category, product.subCategory]"
                    />
                  </b-link>
                  <div class="cart-price-title">
                    <div class="mt-75">
                      Style Code: <span class="cart-price-value">{{ product.styleCode }}</span>
                    </div>
                    <div>
                      Retail Price: <span class="cart-price-value">{{ formatCurrency(product.retailPrice, product.currency) }}</span>
                    </div>
                    <div>
                      Wholesale Selling Price:
                      <span
                        v-if="!product.discountedPrice"
                        class="cart-price-value"
                        :class="{'text-line-through color-red': !!product.discountedPrice}"
                      >
                        {{ formatCurrency(product.price, product.currency) }}
                      </span>
                      <span
                        v-if="product.discountedPrice"
                        class="cart-price-value discounted-price"
                      >
                        {{
                          formatCurrency(product.discountedPrice, product.currency)
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="text-right">
                  <div
                    v-if="collectionEndDate"
                    class="font-weight-normal mt-50"
                  >
                    <span
                      class="mb-2 cart-price-title"
                    >
                      Order Deadline:
                      <span class="cart-price-value">{{ formatDate(collectionEndDate) }} </span>
                    </span>
                  </div>
                  <div class="font-weight-normal mt-50">
                    <span
                      v-if="product.shippingStartDate && product.shippingEndDate"
                      class="mb-2 cart-price-title"
                    >
                      Availability Date:
                      <span class="cart-price-value">{{ formattedDate(product.shippingStartDate) }} to {{ formattedDate(product.shippingEndDate) }}</span>
                    </span>
                  </div>
                  <div
                    v-if="product.isCarton"
                    class="item-quantity mt-1 mr-1"
                  >
                    <span class="cart-price-title">Qty:</span>
                    <b-form-spinbutton
                      v-model="product.quantity"
                      size="sm"
                      class="ml-75"
                      inline
                      :max="getMaxQuantity(product.quantityAvailable)"
                      :disabled="isProductValueDisabled(product.quantityAvailable)"
                      min="1"
                      @input="updateQuantity(product)"
                    />
                    <div
                      v-if="hasProductMaxQuantity(product.quantityAvailable)"
                      class="text-nowrap text-right color-actions-text-grey mt-2-px mr-1"
                    >
                      Max Qty: {{ product.quantityAvailable }} </div>
                  </div>
                </div>
              </div>

              <!-- variants -->
              <product-variants
                :is-deleted="product.isDeleted"
                :is-carton="product.isCarton"
                :items="product.items"
                :is-edit-enabled="true"
                :min-qty-per-size="product.minQtyPerSize || 0"
                @update-variants="
                  (variants) => updateProductVariants(variants, product)
                "
              />
            </b-col>

            <!-- Product Options/Actions -->
            <b-col class="item-options text-center d-flex flex-column flex-md-column md-0 ml-md-auto col-md-2 col-xl-2 align-items-center mt-2 col-12">
              <div class="product-actions">
                <span
                  v-if="$route.name === 'checkout'"
                  v-b-tooltip.hover.bottom.v-primary
                  class="mr-1"
                  title="Replace Product"
                  @click="onClickReplace(product)"
                >
                  <recycle-icon />
                </span>
                <span
                  v-b-modal="`modal-remove-product-${product._id}`"
                  v-b-tooltip.hover.bottom.v-primary
                  class=""
                  title="Delete Product from Cart"
                >
                  <delete-icon />
                </span>
              </div>

              <b-modal
                :id="`modal-remove-product-${product._id}`"
                ok-variant="danger"
                ok-title="Remove product"
                cancel-title="Dismiss"
                modal-class="modal-danger"
                centered
                title="Remove this product?"
                @ok="removeProduct(product)"
              >
                <b-card-text>
                  Are you sure you want to delete this product?
                </b-card-text>
              </b-modal>

              <div class="item-wrapper my-2 my-md-auto ml-auto ml-md-0">
                <div class="item-cost text-center d-flex d-md-block">
                  <h4
                    v-if="variantLoaded"
                    class="item-price mr-50 mr-md-0  font-weight-bolder color-neutral-black"
                  >
                    Total Value:
                    {{ formatCurrency((product.lineTotalPriceWithDiscount || product.lineTotalPrice || 0) , product.currency) }}
                  </h4>
                  <b-card-text
                    v-if="variantLoaded"
                    class="mt-2"
                  >
                    Total Units:
                    {{ formatNumber(product.lineTotalQuantity || 0) }}
                  </b-card-text>
                  <p
                    class="text-primary"
                  >
                    {{ minQtyErrorMessage(product) }}
                  </p>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </template>
    </template>
    <!-- loading -->
    <template v-else>
      <b-card class="text-center p-4">
        <b-spinner />
      </b-card>
    </template>
  </div>
</template>

<script>
import DeleteIcon from '@/@core/assets/svg-components/DeleteIcon.vue'
import RecycleIcon from '@/@core/assets/svg-components/RecycleIcon.vue'
import ShoppingBagDeleteteIcon from '@/@core/assets/svg-components/ShoppingBagDeleteteIcon.vue'
import analytics from '@/@core/utils/analytics'
import { formatDate } from '@/@core/utils/filter'
import { apiToastSuccess, apiToastWarning } from '@/@core/utils/toast'
import {
formatNumber, formatObject,
getMaxQuantity, hasProductMaxQuantity, imageLoadError,
isProductValueDisabled,
searchProductUsingFields,
} from '@/@core/utils/utils'
import constants, {
 CART_PRODUCTS_SEARCH_FIELDS, CART_PRODUCTS_SEARCH_ITEMS_FIELDS, PRODUCTS_LIMIT, marketplaceAccessDeniedMessage,
} from '@/constants'
import store from '@/store'
import {
  FETCH_CART_COUNT,
REMOVE_PRODUCT_FROM_CART, SET_REPLACE_CART_PRODUCT, UPDATE_CART_ITEMS_COUNT,
} from '@/store/modules/shop.module'
import ProductVariants from '@/views/apps/product/detail/ProductVariants.vue'
import { constants as c, utils, productUtil } from '@kingpin-global/kingpin-utils-frontend'

import {
onMounted, onUnmounted,
} from '@vue/composition-api'
import {
BBreadcrumb,
BButton,
BCard,
BCardText,
BCol,
BFormInput,
BFormSpinbutton,
BImgLazy,
BLink,
BModal,
BRow,
BSpinner,
VBModal,
VBTooltip,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import { LOAD_CARTS, UPDATE_CART_ERRORS } from '@/store/modules/checkout-v2.module'
import CartProductErrorText from '@/views/components/cart-products-error/CartProductErrorText.vue'
import CartErrorsMixinVue from '@/views/components/cart-products-error/CartErrorsMixin.vue'
import UserRoleMixinVue from '../UserRoleMixin.vue'
import { useEcommerce, useEcommerceUi } from '../useEcommerce'
import BuyingSummary from './BuyingSummary.vue'
import {
  getCartProductsErrorStatusText,
 getSortedCartProducts,
 initializeData, isNotValidMinQty, isProductHasZeroQty, minQtyErrorMessage, parseProductItems, processProductVariants,
} from './cart-products-utils'
import CartErrors from '../../components/cart-products-error/CartErrors.vue'

const { getProductAndCartTotals } = productUtil

const { formatCurrency, formattedDate } = utils

const { BRAND_ACCESS } = c

export default {
  name: 'CartProducts',
  components: {
    BCard,
    BCardText,
    BLink,
    BImgLazy,
    BButton,
    BBreadcrumb,
    BSpinner,
    BRow,
    BCol,
    BFormInput,
    ProductVariants,
    BuyingSummary,
    BModal,
    RecycleIcon,
    DeleteIcon,
    ShoppingBagDeleteteIcon,
    BFormSpinbutton,
    CartErrors,
    CartProductErrorText,
},
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  mixins: [UserRoleMixinVue, CartErrorsMixinVue],
  props: {
    updateDisablePlaceOrder: {
      type: Function,
      default: () => {},
    },
    cartProducts: {
      type: Array,
      default: () => [],
    },
    isPopup: {
      type: Boolean,
      default: false,
    },
    retailerId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      imageLoadError,
      formatDate,
      getMaxQuantity,
      isProductValueDisabled,
      hasProductMaxQuantity,
      marketplaceAccessDeniedMessage,
      isNotValidMinQty,
      minQtyErrorMessage,
      getCartProductsErrorStatusText,
      isProductHasZeroQty,
    }
  },
  computed: {
    ...mapState({
      profile: state => state.auth.profileData,
    }),
    isEnabledMarketplace() {
      return this.profile?.access?.includes(BRAND_ACCESS.MARKETPLACE)
    },
  },
  methods: {
    closeModal() {
      this.$refs.buyingsummary.hide()
    },
  },
  emits: ['update-total', 'update-total-units', 'has-cart-product', 'remove-product', 'remove-color', 'ship-now', 'remove-product-selected-from-brand', 'update-notes'],

  setup(props, { emit, root }) {
    let productsLimit = PRODUCTS_LIMIT
    let [productsSliceX, productsSliceY, pageNumber] = [0, 0, 1]
    let totals = {
      totalPrice: 0,
      totalQuantity: 0,
      totalPriceWithDiscount: 0,
    }
    const {
      variantLoaded,
      isLoaded,
      products,
      productsSlice,
      searchText,
      originalProducts,
      rawProducts,
      collectionEndDate,
      discountInCredit,
      collectionName,
      collectionId,
      isBrand,
      isCartExport,
    } = initializeData(root)

    const {
      onScrollLoader,
      removeProduct,
      onClickReplace,
      updateTotalPriceAndUnits,
      fetchCartProducts,
      updateProductVariants,
      updateQuantity,
      saveCartProducts,
      searchProduct,
    } = setupFunctions({
      emit,
      root,
      products,
      productsSlice,
      originalProducts,
      rawProducts,
    })

    onMounted(() => {
      window.addEventListener('scroll', onScrollLoader)
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', onScrollLoader)
    })

    if (props.cartProducts.length > 0) {
      initializeWithCartProducts(props.cartProducts)
    } else {
      fetchCartProducts()
    }

    const { parseProductImage } = useEcommerceUi()

    return {
      products,
      productsSlice,
      isLoaded,
      variantLoaded,
      formattedDate,
      searchText,
      searchProduct,
      originalProducts,
      collectionEndDate,
      discountInCredit,
      collectionId,
      isCartExport,

      // UI
      removeProduct,
      onClickReplace,
      parseProductImage,
      updateProductVariants,
      saveCartProducts,
      fetchCartProducts,

      // Filter
      formatNumber,
      formatCurrency,
      updateQuantity,
      initializeWithCartProducts,
    }

    function setupFunctions({
      emit,
      root,
      products,
      productsSlice,
      originalProducts,
      rawProducts,
    }) {
      const onScrollLoader = () => {
        if (
          window.scrollY > document.body.scrollHeight - 2000
          && products.value.length > productsSliceX
        ) {
          pageNumber += 1
          productsSliceY = products.value.length < pageNumber * productsLimit
              ? products.value.length
              : pageNumber * productsLimit
          products.value
            .slice(productsSliceX, productsSliceY)
            .forEach(p => productsSlice.value.push(p))
          productsSliceX = productsSliceY
        }
      }

      const removeProduct = product => {
        if (!props.isPopup) {
          removeProductFromCart(product)
          return
        }

        emit('remove-product-selected-from-brand', product)
        removeProductFromProductList(product)
        updateTotalPriceAndUnits()
        apiToastSuccess('Product removed successfully')
      }

      const loadCartHasNoProducts = () => {
        if (!products.value?.length && !isBrand.value) {
          root.$store.dispatch(LOAD_CARTS)
        }
      }

      const removeProductFromProductList = product => {
        const filterOutProduct = prod => prod._id !== product._id
        products.value = products.value.filter(filterOutProduct)
        originalProducts.value = originalProducts.value.filter(filterOutProduct)
        productsSlice.value = productsSlice.value.filter(filterOutProduct)
        loadCartHasNoProducts()
      }

      const { addProductInCart, REMOVE_PRODUCT_FROM_CART_MSG } = useEcommerce()

      const removeProductFromCart = async product => {
        try {
          await store.dispatch(REMOVE_PRODUCT_FROM_CART, {
            productId: product._id,
          })

          apiToastSuccess(REMOVE_PRODUCT_FROM_CART_MSG)
          store.commit(
            UPDATE_CART_ITEMS_COUNT,
            store.state.shop.cartItemsCount - 1,
          )
          await store.dispatch(FETCH_CART_COUNT)
          removeProductFromProductList(product)

          if (products.value.length === 0) {
            emit('has-cart-product', false)
          } else {
            updateTotalPriceAndUnits()
          }

          // remove from total price
          analytics.track(
            constants.TRACKS.ACTIONS.REMOVE_FROM_CART,
            formatObject(product),
          )
          store.commit(UPDATE_CART_ERRORS)
        } catch (err) {
          apiToastWarning(err)
        }
      }

      const onClickReplace = async product => {
        root.$store.commit(SET_REPLACE_CART_PRODUCT, product)
        await root.$router.push({
          name: 'collection/products',
          params: { collectionId: product.collectionId },
        })
      }

      const updateTotalPriceAndUnits = () => {
        const currency = products.value[0].currency
        totals = getProductAndCartTotals(
          products.value,
          products.value[0].discount,
        )
        if (props.isPopup) {
          productsSlice.value = []
          products.value.slice(0, productsSliceY).forEach(p => {
            productsSlice.value.push(p)
          })
        }
        emit(
          'update-total',
          totals.totalPriceWithDiscount || totals.totalPrice || 0,
          currency,
        )
        emit('update-total-units', totals.totalQuantity)
      }

      // fetch product on cart
      const fetchCartProducts = () => {
            const resData = root.$store.getters.getCart
            let hasProducts = false
            const notes = resData?.notes || ''
            emit('update-notes', notes)
            const cartProducts = resData?.products || []
            if (cartProducts.length) {
              // deep copy two objects originalProducts and products
              originalProducts.value = JSON.parse(
                JSON.stringify(cartProducts),
              )
              rawProducts.value = JSON.parse(JSON.stringify(cartProducts))

              products.value = cartProducts
              updateTotalPriceAndUnits()
              productsSliceY = products.value.length < productsLimit
                  ? products.value.length
                  : productsLimit
              products.value
                .slice(0, productsSliceY)
                .forEach(p => productsSlice.value.push(p))
              productsSliceX = productsSliceY
              hasProducts = true
            }
            if (resData.hasOwnProperty('collectionEndDate')) {
              collectionEndDate.value = resData.collectionEndDate
            }
            if (resData.hasOwnProperty('discountInCredit')) {
              discountInCredit.value = resData.discountInCredit
            }
            isLoaded.value = true

            emit('has-cart-product', hasProducts)
            const replacingProduct = root.$store.getters
              .getReplaceCarProduct
            if (replacingProduct) {
              removeProductFromCart(replacingProduct)
              root.$store.commit(SET_REPLACE_CART_PRODUCT, null)
            }
            collectionName.value = resData?.collectionName
            collectionId.value = resData?.collectionId
            if (cartProducts) {
              analytics.track(
                constants.TRACKS.PAGES.RETAILER_OPENS_BASKET,
                formatObject({
                  collectionName: collectionName.value,
                  noOfProductsInCart: resData?.products?.length || 0,
                }),
              )
            }
      }

      let isCartModified = false

      // update product variant => recalculate total
      const updateProductVariants = (variants, product) => {
        if (isBrand.value) {
          analytics.track(
            constants.TRACKS.ACTIONS.BRAND_UPDATES_PRODUCT_IN_CART,
            formatObject(product),
          )
        }
        isCartModified = true
        product.variants = variants
        const items = parseProductItems(product)
        product.items = items
        const index = originalProducts.value.findIndex(
          p => p._id === product._id,
        )
        originalProducts.value[index].items = items

        if (!product.isCarton) {
          originalProducts.value[index].hasError = product?.hasError || false
        }

        updateTotalPriceAndUnits()
        variantLoaded.value = true
      }

      const updateQuantity = product => {
        if (isBrand.value) {
          analytics.track(
            constants.TRACKS.ACTIONS.BRAND_UPDATES_PRODUCT_IN_CART,
            formatObject(product),
          )
        }
        isCartModified = true
        const index = originalProducts.value.findIndex(
          p => p._id === product._id,
        )
        originalProducts.value[index].quantity = product.quantity
        updateTotalPriceAndUnits()
      }

      // save products to cart
      const saveCartProducts = async (
        notesPayload = {},
        shouldSaveCart = false,
      ) => new Promise((resolve, reject) => {
          if (!shouldSaveCart && !isCartModified && !props.isPopup) {
            resolve()
            return
          }

          // Array for show toast messages
          const minQtyValidationMessages = []
          products.value.forEach(prod => {
            // Check if product is carton and quantity is less than minimum quantity per carton
            if (!prod?.isCarton && prod?.minQtyPerSize) {
              prod.variants.forEach(item => {
                for (const k in item) {
                  if (item.hasOwnProperty(k) && k !== 'color') {
                    if (item[k].value && item[k].value < prod.minQtyPerSize) {
                      minQtyValidationMessages.push(
                        `Minimum quantity per size for "${prod.name}" is ${prod.minQtyPerSize}`,
                      )
                      break
                    }
                  }
                }
              })
            }
            // Check if product is not carton and quantity is less than minimum quantity per style
              if (prod?.minQtyPerStyle
              && prod.lineTotalQuantity < prod?.minQtyPerStyle
            ) {
              minQtyValidationMessages.push(
                `Minimum quantity per style for "${prod.name}" is ${prod.minQtyPerStyle}`,
              )
            }
          })

          // Show warning toast messages
          if (minQtyValidationMessages.length > 0) {
            minQtyValidationMessages.forEach(message => {
              apiToastWarning(message)
            })
            reject(new Error('Minimum quantity validation failed'))
            return
          }

          isCartModified = false
          const payload = {
            products: [],
          }

          if (props.isPopup && props.retailerId) {
            payload.retailerId = props.retailerId
          }

          products.value.forEach(prod => {
            const originalProduct = rawProducts.value.find(
              p => p._id === prod._id,
            )

            if (prod.isCarton) {
              if (originalProduct?.quantity !== prod.quantity || isBrand.value) {
                payload.products.push({
                  productId: prod._id,
                  quantity: prod.quantity,
                })
              }
            } else if (
              Array.isArray(prod.variants)
              && prod.variants.length > 0
            ) {
              processProductVariants(prod, originalProduct, payload, isBrand)
            }
          })
          const params = {
            isUpdateFromCartPage: true,
          }

          // If this is not brand, then save cart
          if (!payload?.products?.length && !isBrand.value) {
            resolve()
            return
          }
          addProductInCart(payload, params)
            .then(res => {
              if (!props.isPopup && !shouldSaveCart) apiToastSuccess(res.data.message || 'Saved cart!')
              if (!isBrand.value) {
                analytics.track(
                  constants.TRACKS.ACTIONS.RETAILER_SAVES_CART,
                  formatObject({
                    noOfProductsInCart: payload.products?.length,
                    collectionName: collectionName.value,
                  }),
                )
              }
              if (notesPayload?.notes) {
                addProductInCart(notesPayload)
              }
              rawProducts.value = JSON.parse(JSON.stringify(originalProducts.value))
              resolve()
            })
            .catch(err => {
              if (err?.response?.data?.data?.hasRetailerCart) {
                isCartExport.value = shouldSaveCart
                root.$bvModal.show('clear-retailer-cart')
              } else {
                apiToastWarning(err)
              }
              reject(new Error('Failed to save cart'))
            })
        })

        const searchFields = CART_PRODUCTS_SEARCH_FIELDS
        const itemFields = CART_PRODUCTS_SEARCH_ITEMS_FIELDS
      const searchProduct = () => {
        // Search for the products
        products.value = originalProducts.value.filter(product => searchProductUsingFields(
            product,
            searchFields,
            searchText.value,
            itemFields,
          ))

        // Assign item values which are changed by users
        products.value.forEach(product => {
          for (const [key, value] of Object.entries(product)) {
            if (key !== 'items') {
              product[key] = value
            }
          }
        })

        // Do pagination in cart products
        pageNumber = 0
        productsSliceY = products.value.length < productsLimit
            ? products.value.length
            : productsLimit
        productsSlice.value = products.value.slice(0, productsSliceY)
        productsSliceX = productsSliceY
      }

      return {
        onScrollLoader,
        removeProduct,
        removeProductFromProductList,
        removeProductFromCart,
        onClickReplace,
        updateTotalPriceAndUnits,
        fetchCartProducts,
        updateProductVariants,
        updateQuantity,
        saveCartProducts,
        searchProduct,
        initializeWithCartProducts,
      }
    }

    function initializeWithCartProducts(cartProducts, isErrorProducts = false) {
      let hasProducts = false
      productsLimit = 1000
      if (cartProducts?.length) {
        originalProducts.value = JSON.parse(JSON.stringify(cartProducts))
        rawProducts.value = JSON.parse(JSON.stringify(cartProducts))
        if (isErrorProducts) {
          products.value = getSortedCartProducts(cartProducts)
          productsSlice.value = []
          productsSliceY = 0
        } else {
          products.value = cartProducts
        }
        updateTotalPriceAndUnits()
        productsSliceY = products.value.length < productsLimit
            ? products.value.length
            : productsLimit
        products.value
          .slice(0, productsSliceY)
          .forEach(p => productsSlice.value.push(p))
        productsSliceX = productsSliceY
        hasProducts = true
      }
      emit('has-cart-product', hasProducts)
      isLoaded.value = true
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~@core/scss/base/pages/app-cart-products.scss';
</style>
