<template>
  <div
    class="d-flex h-auto align-items-center"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <b-dropdown
      :id="`wishlist-dropdown-${product._id}`"
      :class="showCheckBox ? 'pointerevents' : ''"
      :style="getStyleCardWidth"
      class="product-wishlist-dropdown"
      variant="link"
      toggle-class="text-decoration-none p-0 m-0 text-capitalize intro-add-wishlist"
      no-caret
      :no-flip="!isProductDetailsPage"
      dropdown
      :offset="!isProductDetailsPage ? 64 - cardWidth : ''"
      :disabled="disabled"
      @show="onToggleWishlistDropdown(true)"
      @hide="onToggleWishlistDropdown(false)"
    >
      <template #button-content>
        <div
          class="wislist-icon-with-dropdown d-flex align-items-center justify-content-center h-100"
          :class="{ 'btn btn-outline-primary btn-sm': isProductDetailsPage, 'wislist-dropdown-width': !isProductDetailsPage }"
        >
          <div class="heart-with-count-badge">
            <feather-icon
              icon="HeartIcon"
              :size="heartIconSize"
              :class="{ 'text-danger fill-danger': productWishlistsCount }"
            />
            <div
              v-if="productWishlistsCount"
              class="count-badge text-white bg-primary rounded-pill border border-white d-flex align-items-center justify-content-center"
              :class="{ 'count-badge-product-details': isProductDetailsPage }"
            >
              <div>
                {{ productWishlistsCount }}
              </div>
            </div>
          </div>
          <div
            v-if="isProductDetailsPage"
            class="pl-10-px"
          >
            Wishlist
          </div>
          <feather-icon
            :icon="!!product.isOpenedDropdown ? 'ChevronUpIcon' : 'ChevronDownIcon'"
            size="20"
            class="ml-5-px"
          />
        </div>
      </template>
      <template v-if="productWishlistsCreatedByRetailer && productWishlistsCreatedByRetailer.length">
        <b-dropdown-header
          :id="'my_wishlists' + product._id"
          class="bg-gray"
        >
          My wishlists
        </b-dropdown-header>
        <b-dropdown-item
          v-for="wishlist in productWishlistsCreatedByRetailer"
          :key="product._id + wishlist._id"
          @click="onSelectWishlistOnProduct(wishlist)"
        >
          <div class="d-flex align-items-center">
            <div class="wishlist-tick-icon">
              <feather-icon
                v-if="product.wishlists.includes(wishlist._id)"
                icon="CheckIcon"
                size="16"
                class="text-success"
              />
            </div>
            <div class="entity-name-text">
              {{ wishlist.name }}
            </div>
          </div>
        </b-dropdown-item>
      </template>
      <template v-if="productWishlistsSharedByBrand && productWishlistsSharedByBrand.length">
        <b-dropdown-header :id="'shared_by_brand' + product._id">
          Shared by brand
        </b-dropdown-header>
        <b-dropdown-item
          v-for="wishlist in productWishlistsSharedByBrand"
          :key="product._id + wishlist._id"
          @click="onSelectWishlistOnProduct(wishlist)"
        >
          <div class="d-flex align-items-center">
            <div class="wishlist-tick-icon">
              <feather-icon
                v-if="product.wishlists.includes(wishlist._id)"
                icon="CheckIcon"
                size="16"
                class="text-success"
              />
            </div>
            <div class="entity-name-text">
              {{ wishlist.name }}
            </div>
          </div>
        </b-dropdown-item>
      </template>
      <b-dropdown-item @click="createWishlist()">
        <div>
          <feather-icon
            icon="PlusIcon"
            size="16"
          />
          <span class="entity-name-text">Create wishlist</span>
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import { SET_IS_NO_HOVER_PRODUCT_CARD } from '@/store/modules/shop.module'
import { BDropdown, BDropdownHeader, BDropdownItem } from 'bootstrap-vue'

const { PRODUCTS_PAGE_WISHLISTS } = constants.TRACKS.ACTIONS

export default {
    name: 'ProductWishlistsDropdown',
    components: {
        BDropdown,
        BDropdownHeader,
        BDropdownItem,
    },
    props: {
        product: {
            type: Object,
            required: true,
        },
        showCheckBox: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        wishlists: {
            type: Array,
            default: () => [],
        },
        cardWidth: {
            type: Number,
            default: 0,
        },
        isProductDetailsPage: {
            type: Boolean,
            default: false,
        },
        isRecommendedProduct: {
            type: Boolean,
            default: false,
        },
        isOpenedDropdown: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            productWishlistsCreatedByRetailer: [],
            productWishlistsSharedByBrand: [],
            isActiveDropdown: false,
        }
    },
    computed: {
        heartIconSize() {
            return this.isProductDetailsPage ? '20' : '24'
        },
        productWishlistsCount() {
          return this.product?.wishlists?.length || 0
        },
        getStyleCardWidth() {
          if (this.isProductDetailsPage) {
            return {
                '--card-width': '250px',
            }
          }

          if (!this.cardWidth) {
            return {}
          }
            return {
                '--card-width': `${this.cardWidth}px`,
            }
        },
        pageType() {
            if (this.isProductDetailsPage) {
              return 'Product details page'
            } else if (this.isRecommendedProduct) {
              return 'Recommended products page'
            } else {
              return 'Collection products page'
            }
        },
    },
    watch: {
        wishlists: {
            handler() {
                this.setWishlistsForTheDropdown()
            },
            immediate: true,
        },
    },
    methods: {
        onMouseEnter() {
          this.isActiveDropdown = true
          this.$store.commit(SET_IS_NO_HOVER_PRODUCT_CARD, true)
        },
        onMouseLeave() {
          this.isActiveDropdown = false
          if (!this.isOpenedDropdown) {
            this.$store.commit(SET_IS_NO_HOVER_PRODUCT_CARD, false)
          }
        },
        onSelectWishlistOnProduct(wishlist) {
            this.$emit('select-wishlist-on-product', wishlist)
            const action = this.product.wishlists.includes(wishlist._id) ? 'REMOVED_PRODUCT_FROM' : 'ADDED_PRODUCT_TO'
            const source = wishlist.isBrandInitiated ? 'SHARED_WISHLIST' : 'MY_WISHLIST'
            const trackEvent = `RETAILER_${action}_A_WISHLIST_BY_CLICKING_A_${source}_IN_THE_WISHLISTS_DROPDOWN`

            analytics.track(PRODUCTS_PAGE_WISHLISTS[trackEvent], {
                product: this.product,
                wishlist,
                pageType: this.pageType,
            })
        },
        createWishlist() {
            this.$emit('create-wishlist')
            analytics.track(PRODUCTS_PAGE_WISHLISTS.RETAILER_CLICKS_CREATE_WISHLIST_IN_THE_WISHLISTS_DROPDOWN, {
                product: this.product,
                pageType: this.pageType,
            })
        },
        onToggleWishlistDropdown(isOpened = false) {
            this.$emit('toggle-wishlist-dropdown', isOpened)
            if (isOpened) {
                analytics.track(PRODUCTS_PAGE_WISHLISTS.RETAILER_OPENS_THE_WISHLISTS_DROPDOWN_BY_CLICKING_ON_THE_HEART_ICON, {
                    product: this.product,
                    pageType: this.pageType,
                })
            } else {
                analytics.track(PRODUCTS_PAGE_WISHLISTS.RETAILER_CLOSES_THE_WISHLISTS_DROPDOWN, {
                    product: this.product,
                    pageType: this.pageType,
                })
                if (!this.isActiveDropdown) this.$store.commit(SET_IS_NO_HOVER_PRODUCT_CARD, false)
            }
        },
        setWishlistsForTheDropdown() {
            this.productWishlistsCreatedByRetailer = this.wishlists.filter(wishlist => !wishlist?.isBrandInitiated)
            this.productWishlistsSharedByBrand = this.wishlists.filter(wishlist => wishlist?.isBrandInitiated)
        },
    },

}
</script>

<style lang="scss">
@import '@/@core/scss/base/bootstrap-extended/utilities';
@import '@/@core/scss/base/bootstrap-extended/include';

.product-wishlist-dropdown {
    .wislist-dropdown-width {
        width: 60px;
    }

    .wislist-icon-with-dropdown {
        .heart-with-count-badge {
            position: relative;

            .count-badge {
                position: absolute;
                bottom: -2px;
                right: -8px;
                font-size: 0.8rem;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                text-align: center;
                align-items: center;
            }

            .count-badge-product-details {
                width: 16px;
                height: 16px;
            }
        }

        .fill-danger {
            fill: $danger;
        }
    }

    .dropdown-menu {
        max-height: 350px;
        overflow-y: auto;
        margin-top: 5px;
        width: var(--card-width);
        z-index: 20;

        .dropdown-header {
            font-weight: 700;
            line-height: unset;
            background-color: $gray-300;
        }

        .wishlist-tick-icon {
            width: 20px;
        }
        .entity-name-text {
          text-wrap: wrap;
        }
    }
}
</style>
